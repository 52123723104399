<template>
    <!-- /test/app-table/mobile-services -->
    <div class="page-test-app-table-mobile-services container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="mobile-services-table"

                    title="Recent Provisioning"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    route="mobile-services"

                    @details="onDetails"
                    @remove="onRemove"
                ></app-table>
            </div>
        </div>
    </div>
</template>

<script>
import appTable from '@/components/app-table'

export default {
    components: {
        appTable,
    },

    data() {
        return {
            cols: [
                { key: 'name',               title: 'Name',               highlight: true,                                                  },
                { key: 'service-type',       title: 'Service type',                                                                         },
                { key: 'service-identifier', title: 'Service identifier',                                                                   },
                { key: 'status',             title: 'Status',                              type: 'status',                                  },
                { key: 'details',                                                                          action: 'details', icon: 'link', },
                { key: 'remove',                                                                           action: 'remove',                },
            ],
            rows: [],

            loading: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                this.rows = [
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                    {
                        name: 'Test device',
                        'service-type': '12345678',
                        'service-identifier': '123456',
                        status: {
                            status: 'cancelled', // _table-tag-colors.scss
                            text: 'Cancelled',
                        },
                    },
                ]

                this.loading = false
            }, delay_in_ms)
        },

        onDetails(row) {
            console.log('onDetails', JSON.parse(JSON.stringify(row)))
        },

        onRemove(row) {
            console.log('onRemove', JSON.parse(JSON.stringify(row)))
        },
    },
}
</script>

<style lang="scss">
.page-test-app-table-mobile-services {
    .mobile-services-table {
        margin: 48px 0;

        @include table-cols-width((180px, 110px, 130px, 150px, 50px, 50px), true);
    }
}

@media (max-width: $tablet-size) {
    .page-test-app-table-mobile-services {
        .mobile-services-table {
            @include table-cols-width((140px, 135px, 135px, 150px, 50px, 50px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-test-app-table-mobile-services {
        .mobile-services-table {
            @include table-cols-width-mobile((100px, 145px, 24px), true);
        }
    }
}
</style>